<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_04.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">
              Não indenização da terra nua
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="Desconsideração da terra nua para fins indenizatórios em FNE"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Durante a Fase de Negociação Extrajudicial (FNE), a Fundação
                  Renova dita unilateralmente quais, dentre os danos contidos no
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_sistematizacao_dossie' }"
                    >dossiê</router-link
                  >, serão indenizados nas tratativas extrajudiciais. Isto é,
                  após um longo período de cadastro, ao final do processo, a
                  Fundação mantida pelas empresas causadoras do desastre–crime
                  e, portanto, defensora de seus interesses, é que determina não
                  só quanto deve a cada pessoa atingida, como também
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_desconsideracao_cadastro',
                    }"
                    >qual dano será pago e qual não será</router-link
                  >.
                </p>
                <p>
                  Neste momento, importante destacar que adentrar nesta Fase não
                  é exatamente uma escolha livre para o núcleo familiar. Isso
                  porque o acesso à justiça em Mariana foi mitigado em
                  detrimento de uma suposta “indenização célere”, que seria a
                  FNE, permanecendo grande descrença no sistema de justiça como
                  solucionador desse conflito. Sendo assim, houve uma grande
                  aposta na FNE, gerando nas pessoas atingidas a sensação de que
                  só teriam chance de receber suas indenizações se confiassem
                  nas etapas do procedimento extrajudicial.
                </p>
                <p>
                  Essa ressalva é fundamental para a compreensão de que, ao não
                  indenizar determinado dano em FNE, a Fundação Renova busca
                  tornar praticamente impossível a sua reivindicação também por
                  outras vias. Na prática, o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_ser_atingido_tempos',
                    }"
                    >decurso do tempo</router-link
                  >, somado à exaustão de grande parte dos envolvidos, faz com
                  que o fato de não indenizar um dano em FNE comprometa
                  demasiadamente o alcance da
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >, sobretudo para aqueles e aquelas que aceitaram as propostas
                  oferecidas pela Fundação Renova.
                </p>
                <p>
                  Um dos danos não reconhecidos pela Fundação Renova e,
                  portanto, não indenizado na FNE, é o dano ao terreno atingido,
                  à terra nua. De forma contraditória, esse dano é reconhecido
                  pela própria empresa contratada pela Fundação Renova para
                  fazer a valoração dos danos, a Synergia. Na maioria dos laudos
                  técnicos elaborados por ela, há uma análise sobre o terreno
                  atingido, culminando em sua valoração para indenização.
                  Entretanto, nas propostas indenizatórias apresentadas pela
                  Fundação Renova aos núcleos familiares atingidos são retirados
                  esses valores sobre a terra nua, sob o pretexto de que ainda
                  aguardam deliberações institucionais para definição de
                  pagamento e destinação desses valores, e que essas questões
                  estariam sendo discutidas fora da FNE.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Obviamente que, ao reivindicar a indenização pelos danos
                  ocasionados à terra nua, não se está discutindo a propriedade
                  do terreno, uma vez que em Mariana as pessoas atingidas
                  conquistaram o direito — garantido juridicamente — de que seus
                  terrenos não poderiam ser objeto de permuta. Ou seja,
                  diferentemente de outros territórios atingidos, por aqui não
                  haveria, em tese, a possibilidade de as empresas indenizarem a
                  pessoa atingida pelo dano ao terreno como forma de comprá-lo
                  do proprietário. Esse direito é fundamental, pois garante que
                  as empresas causadoras do desastre-crime não possam obter
                  vantagens a partir do próprio ato ilícito cometido, com a
                  aquisição do imóvel que deixou de ser útil unicamente devido à
                  lama de rejeitos tóxicos que o inundou.
                </p>
                <p>
                  É importante reafirmar, portanto, que esse direito de
                  manutenção da propriedade do terreno nada tem a ver com a
                  indenização pela destruição ocasionada ao mesmo. É
                  incontestável que o rompimento da barragem desvalorizou os
                  terrenos de forma extrema, a ponto de tornar sua utilização
                  habitual inviável. Sendo assim, o que a Fundação Renova
                  deveria considerar e indenizar na FNE é o valor correspondente
                  a todo dano causado no terreno, sem que esse pagamento
                  alterasse ou interferisse, como já dito, no título de
                  propriedade do mesmo.
                </p>
                <p>
                  Diante de todo esse contexto de violações e incertezas vividas
                  cotidianamente pelas pessoas atingidas em Mariana, o receio em
                  não ter indenizados os danos a seus terrenos é extremamente
                  justificável. Os questionamentos muitas vezes feitos pelas
                  famílias passam inclusive, pelo medo de que, ao prolongar essa
                  discussão por anos, haja alguma determinação do Poder Público
                  e/ou das instituições de Justiça encaminhada sem ouvir a
                  população atingida e desconsiderando seus interesses.
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_ser_atingido_tempos',
                    }"
                    >Quanto mais o tempo passa</router-link
                  >
                  sem que haja reparação por determinado dano, maior o risco de
                  que esse direito seja esquecido ou negligenciado por aqueles
                  que deveriam protegê-lo. Por esse motivo, é extremamente
                  necessário e urgente que a decisão sobre a indenização da
                  terra nua seja encaminhada juntamente com as famílias
                  atingidas.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico</a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de
                  Fundão<sup>1</sup>.
                </p>

                <p style="text-align: center">
                  <strong
                    >Autoria: Equipe de Assessoria Jurídica da ATI de
                    Mariana</strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p style="margin-bottom: 0;">
                <sup>1</sup>
                <a
                  target="_blank"
                  class="pink--text"
                  style="text-decoration: none;"
                  href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                  >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center">
          <v-col class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_violacoes_dano_moral' }"
              color="#EDBD43"
            >
              <strong
                >Conhecer "Dano moral atrelado a propriedade do terreno"</strong
              >
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Não indenização da terra nua",
          href: "conteudos_tematicos_violacoes_indenizacao_terra",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
